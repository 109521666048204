// Package Imports
import React from 'react'

// Product Imports
import { Button, Popover } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import "../treeNode.css"
import { key } from 'glyphicons';

const PopoverCard = (props) => {
    const { node } = props;
    console.log("tree:::PopoNode", node)

    const mergedData = node?.decision?.decisionInput?.reduce((acc, item) => {
        return { ...acc, ...item };
    }, {});
    console.log("tree:::mergedData", mergedData)

    const showDetails = ['decisionName', 'decisionVersion', 'rulePlan']

    const content = (
        <div>
            {
                Object.keys(node?.decision).map((key) =>
                    showDetails.includes(key) && (
                        <p key={key} className='decision-details-key'>{key[0].toUpperCase() + key.slice(1)} - <span className='decision-details-value'>{node.decision[key]}</span> </p>
                    )
                )
            }
            <table >
                <thead>
                    <tr>
                        {Object.keys(mergedData).map((key, index) => (
                            <th
                                key={index}
                                className="pop-over-cell"
                            >
                                {key}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Object.values(mergedData).map((value, index) => (
                            <td
                                key={index}
                                className="pop-over-cell pop-over-cell-value"
                            >
                                {value}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
    return (
        <Popover content={content}>
            <InfoCircleTwoTone />
        </Popover>
    )
}

export default PopoverCard